import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Container, Drawer, List, Divider, ListItem, ListItemText } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import ElevationScroll from './ElevationScroll';
import MenuIcon from '@material-ui/icons/Menu';

import { SSLogo } from '../../images';

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  navLink: {
    textDecoration: 'none',
    color: theme.palette.link,
    '&.active': {
      color: theme.palette.primary.main,
    },
  },
  toolbar: {
    padding: 0,
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'black',
  },
  logo: {
    marginRight: 4,
    height: theme.spacing(5),
  },
  list: {
    width: '60vw',
  },
  registrationLinks: {
    '& > a': {
      textDecoration: 'none',
      color: 'black',
    },
  },
}));

export default () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  return (
    <>
      <ElevationScroll>
        <AppBar position="sticky" color="inherit">
          <Container maxWidth="lg">
            <Toolbar className={classes.toolbar}>
              <MenuIcon className={classes.menuButton} onClick={() => setDrawerOpen(true)} />
              <NavLink className={classes.logoLink} to="/" title="SAFESHEPHERD">
                <SSLogo className={classes.logo} /> Safe Shepherd
              </NavLink>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className={classes.list} onClick={() => setDrawerOpen(false)}>
          <List>
            <NavLink to="/" exact className={classes.navLink} activeClassName="active">
              <ListItem button>
                <ListItemText primary={'Home'} />
              </ListItem>
            </NavLink>
            <NavLink to="/how" className={classes.navLink} activeClassName="active">
              <ListItem button>
                <ListItemText primary={'How it works'} />
              </ListItem>
            </NavLink>
          </List>
          <Divider />
          <List className={classes.registrationLinks}>
            <a href={process.env.REACT_APP_DASHBOARD_SIGNUP_SITE_URL} rel="noopener noreferrer">
              <ListItem button>
                <ListItemText primary={'Sign Up'} />
              </ListItem>
            </a>
            <a href={process.env.REACT_APP_DASHBOARD_LOGIN_SITE_URL} rel="noopener noreferrer">
              <ListItem button>
                <ListItemText primary={'Login'} />
              </ListItem>
            </a>
          </List>
        </div>
      </Drawer>
    </>
  );
};
