import React from 'react';
import CommonHowSection from '../Utils/CommonHowSection';

export default () => {
  const secondaryText = `You'll receive instant, real-time alerts via email whenever you're found in a private database or exposed
  online... before anyone else does. You can rest easy knowing that Safe Shepherd has your back and our privacy
  experts are on-call, ready to help you.`;

  return <CommonHowSection heading="Instant Privacy Alerts" secondaryText={secondaryText} />;
};
