import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 285,
    padding: theme.spacing(6.5),
    border: '2px solid black',
    borderRadius: '52px',
    boxShadow: '0px 22px 30px rgba(0, 0, 0, 0.05)',
    opacity: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
      height: 300,
      maxWidth: 235,
      marginTop: theme.spacing(2),
      boxShadow: 'none',
    },
    '@media (max-width: 320px)': {
      padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
    },
    '& > p': {
      textAlign: 'left',
      letterSpacing: 0,
      color: '#131B40',
      opacity: 0.5,
      [theme.breakpoints.down('sm')]: {
        width: 260,
      },
      '@media (max-width: 320px)': {
        fontSize: 12,
        width: 240,
      },
    },
    '& > h2': {
      fontSize: '1.5rem',
      fontWeight: 600,
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontWeight: 600,
      },
      '@media (max-width: 320px)': {
        fontSize: 18,
      },
    },
    '&.second': {
      borderColor: theme.palette.secondary.main,
      marginBottom: theme.spacing(4),
      '& > h2': {
        color: theme.palette.secondary.main,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 'unset',
      },
    },
    '&.third': {
      borderColor: '#80BEBE',
      '& > h2': {
        color: '#80BEBE',
      },
    },
  },
  cardContainer: {
    display: 'flex !important',
    justifyContent: 'center',
  },
}));

const CARDS = {
  first: {
    title: 'Reduce Marketing',
    subTitle: 'And Spam',
    body:
      'Safe Shepherd targets data-brokers (companies which sell your personal information) and opts you out of their marketing databases.',
    subBody:
      "By removing your home address and email address from the internet, you'll receive less junk mail, in your home mailbox and your email inbox.",
  },
  second: {
    title: 'Delete Your',
    subTitle: 'Personal Information',
    body:
      "Ever wonder how and employer, ex-lover, or creep can find you online? It's because websites and private database publish your personal information for anyone to find.",
    subBody:
      'Safe Shepherd will show you all of the exposures of your personal information, and help you delete your information.',
  },
  third: {
    title: 'Prevent Identity',
    subTitle: 'Theft',
    body:
      "Tons of companies will monitor your credit and alert you when you've been compromised, but they don't prevent the problem from happening in the first place. Safe Shepherd does.",
    subBody:
      'When your personal information is exposed online,  anyone cand find it - including criminals. When you protect your personal information, you prevent it from falling into the wrong hands.',
  },
};

const InformationCard = ({ title, subTitle, body, subBody, second, third }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardContainer}>
      <Card
        className={classnames({
          [classes.card]: true,
          second,
          third,
        })}
      >
        <Typography variant="h2">
          {title} <br />
          {subTitle}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {body}
          <br />
          <br />
          {subBody}
        </Typography>
      </Card>
    </div>
  );
};

export const IntroCardOne = () => (
  <InformationCard
    title={CARDS.first.title}
    subTitle={CARDS.first.subTitle}
    body={CARDS.first.body}
    subBody={CARDS.first.subBody}
  />
);

export const IntroCardTwo = () => (
  <InformationCard
    title={CARDS.second.title}
    subTitle={CARDS.second.subTitle}
    body={CARDS.second.body}
    subBody={CARDS.second.subBody}
    second
  />
);

export const IntroCardThree = () => (
  <InformationCard
    title={CARDS.third.title}
    subTitle={CARDS.third.subTitle}
    body={CARDS.third.body}
    subBody={CARDS.third.subBody}
    third
  />
);
