export default theme => ({
  contentContainer: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: 'unset',
    },
    '& > h6': {
      marginBottom: theme.spacing(2),
    },
  },
  handbookContentContainer: {
    '& > h6': {
      marginBottom: theme.spacing(2),
    },
  },
  heading: {
    marginBottom: `${theme.spacing(3)}px !important`,
    opacity: 0.35,
  },
  navLink: {
    textDecoration: 'none',
    color: theme.palette.link,
    '&.active': {
      color: theme.palette.secondary.main,
    },
  },
});
