import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  buttonStyles: {
    background: '#F0F1F3',
    border: '1px solid #EBECEE',
    borderRadius: 19,
    opacity: 1,
    boxShadow: 'none',
    padding: `${theme.spacing(3.5)}px ${theme.spacing(10)}px`,
    fontSize: '1.2em',
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Button variant="contained" className={classes.buttonStyles}>
      See How It Works
    </Button>
  );
};
