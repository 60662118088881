import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import SeeHowButton from '../SeeHowButton';
import { IntroCardOne, IntroCardTwo, IntroCardThree } from '../Intro/Cards';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginTop: theme.spacing(10),
    textAlign: 'center',
    '& > h6': {
      marginBottom: theme.spacing(2.5),
    },
  },
  link: {
    textDecoration: 'none',
  },
  cardWrapper: {
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
  },
  carousel: {
    '& .slick-prev:before, & .slick-next:before': {
      display: 'none',
    },
    '& .slick-dots': {
      bottom: '-50px',
    },
    '&.slick-dots, li button:before': {
      width: 10,
      height: 10,
      border: '2px solid #26C4C4',
      borderRadius: '50%',
      opacity: 0.4,
      color: 'transparent',
    },
    '&.slick-dots, li.slick-active button:before': {
      background: '#26C4C4 0% 0% no-repeat padding-box',
      color: 'transparent',
    },
  },
}));

var carouselSettings = {
  dots: true,
  infinite: true,
  autoplay: true,
  initialSlide: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Slider {...carouselSettings} className={classes.carousel}>
        <IntroCardOne />
        <IntroCardTwo />
        <IntroCardThree />
      </Slider>
      <Grid item xs={12} sm={12} md={5} lg={11} className={classes.buttonContainer}>
        <Typography variant="h6" color="textSecondary">
          Have questions?
        </Typography>
        <Link to="/how" className={classes.link}>
          <SeeHowButton />
        </Link>
      </Grid>
    </div>
  );
};
