import React, { useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';

const ScrollToTopRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [rest.path]);

  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default withRouter(ScrollToTopRoute);
