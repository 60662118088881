import React from 'react';
import { Helmet } from 'react-helmet';

export default ({ title, content }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {content && <meta name="description" content={content} />}
    </Helmet>
  );
};
