import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContentsLinks from '../PrivacyHandbook/ContentsLinks';
import GuidesLinks from '../PrivacyHandbook/GuidesLinks';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& .MuiExpansionPanel-root, & .Mui-expanded': {
      marginTop: 'unset',
      marginBottom: 'unset',
    },
  },
  heading: {
    fontWeight: 500,
  },
}));

export default ({ guides, contents }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ExpansionPanel square={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading} color="primary">
            Articles
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ContentsLinks links={contents} />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel square={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading} color="primary">
            Opt-Out Guides
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <GuidesLinks links={guides} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};
