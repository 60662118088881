import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minWidth: '100%',
  },
  title: {
    color: theme.palette.primary.main,
  },
  subtitle: {
    color: '#686868',
  },
  getStarted: {
    marginTop: theme.spacing(6),
    '& button': {
      padding: '30px 80px',
      fontSize: '1.2em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
  navLink: {
    textDecoration: 'none',
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item>
          <Typography variant="h5" className={classes.title}>
            <b>How To Use</b>
          </Typography>
          <Typography variant="h2" className={classes.title}>
            <b>The Privacy Handbook</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.subtitle}>
            Why We Created the Privacy Handbook
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            We believe that your personal data should never be used against you. Privacy should be accessible, and right
            now it’s just too darn complicated. This Handbook aims to give you a single source for the instructions
            you’ll need you remove your personal information from sites that expose your information.
          </Typography>
        </Grid>
        <Grid item className={classes.getStarted}>
          <NavLink to="411locate" className={classes.navLink}>
            <Button variant="contained" color="primary">
              Get Started
            </Button>
          </NavLink>
        </Grid>
      </Grid>
    </div>
  );
};
