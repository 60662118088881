import React from 'react';
import Header from '../components/Header';
import Policy from '../components/Privacy/Policy';
import PageHead from '../components/Utils/PageHead';

export default () => (
  <div>
    <PageHead title="Privacy Policy | Safe Shepherd" />
    <Header title={'Privacy Policy'} subtitle={'Last Updated January 1, 2020'} />
    <Policy />
  </div>
);
