import { useState, useEffect } from 'react';
import { db } from '../firebase';

export default (collectionName, docId) => {
  const [result, setResult] = useState({});
  const [initializing, setInitializing] = useState(true);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    let unsub;
    try {
      unsub = db
        .collection(collectionName)
        .doc(docId)
        .onSnapshot(doc => {
          if (Object.keys(doc.data() || {}).length) setResult(doc.data());
          if (initializing) setInitializing(false);
        });
    } catch (err) {
      setError(err);
    }

    return unsub;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName, docId]);

  return [result, initializing, error];
};
