import React from 'react';
import HowHeader from '../components/How/HowHeader';
import AlertDescription from '../components/How/AlertDescription';
import InstantAlerts from '../components/How/InstantAlerts';
import { makeStyles } from '@material-ui/core/styles';
import PageHead from '../components/Utils/PageHead';

const useStyles = makeStyles(theme => ({
  background: {
    padding: theme.spacing(3),
    borderTopRightRadius: '40%',
    borderBottomLeftRadius: '40%',
    background: 'white',
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <>
      <PageHead
        title="How Safe Shepherd Works | Safe Shepherd"
        content="Safe Shepherd identifies and removes your personal information from over 100+ people-search websites. Over 200k satisfied users. Start protecting your information today."
      />
      <AlertDescription />
      <div className={classes.background}>
        <InstantAlerts />
        <HowHeader />
      </div>
    </>
  );
};
