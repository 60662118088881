import React from 'react';
import Header from '../components/Header';
import Terms from '../components/ToS/Terms';
import PageHead from '../components/Utils/PageHead';

export default () => (
  <>
    <PageHead title="Terms of Service | Safe Shepherd" />
    <Header title={'Terms of Service'} subtitle={'Last Updated January 1, 2020'} />
    <Terms />
  </>
);
