import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import { useHistory, useLocation } from 'react-router-dom';

const MixpanelPageView = () => {
  const history = useHistory();
  const location = useLocation();

  const trackLocation = location => {
    mixpanel.track('Page View', { page: location.pathname });
  };

  useEffect(() => {
    trackLocation(location);
    return history.listen(trackLocation);
  }, [history, location]);

  return null;
};

export default MixpanelPageView;
