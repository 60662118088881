import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  footerLinksBackground: {
    background: theme.palette.common.white,
    padding: '50px 0',
  },
  footerLinks: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.link,
    [theme.breakpoints.down('xs')]: {
      margin: '12px 0',
    },
  },
  prompt: {
    textAlign: 'center',
    padding: '120px 0',
    '& > h3': {
      fontWeight: 600,
    },
    '& > h6': {
      marginTop: 40,
      marginBottom: 30,
    },
    [theme.breakpoints.down('sm')]: {
      '& br': {
        display: 'none',
      },
    },
  },
  signUpButton: {
    textDecoration: 'none',
    '& > button': {
      padding: '30px 80px',
      fontSize: '1.2em',
      background: '#26C4C4',
    },
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="md" className={classes.prompt}>
        <Typography variant="h3" color="primary">
          Protect Your Identity
          <br /> In Less Than 3 Minutes.
        </Typography>
        <Typography variant="h6" color="textSecondary">
          Why wait?
        </Typography>
        <a
          href={process.env.REACT_APP_DASHBOARD_SIGNUP_SITE_URL}
          rel="noopener noreferrer"
          className={classes.signUpButton}
        >
          <Button variant="contained" color="secondary">
            Create A Free Account
          </Button>
        </a>
      </Container>
      <div className={classes.footerLinksBackground}>
        <Container maxWidth="md" className={classes.footerLinks}>
          <Link to="/handbook/privacy-basics" className={classes.link}>
            Privacy Handbook
          </Link>
          <Link to="/about" className={classes.link}>
            About
          </Link>
          <Link to="/privacy-policy" className={classes.link}>
            Privacy Policy
          </Link>
          <Link to="/tos" className={classes.link}>
            Terms of Service
          </Link>
        </Container>
      </div>
    </>
  );
};
