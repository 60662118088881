import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { Typography, Hidden } from '@material-ui/core';
import linksStyles from './LinksStyles';

const useStyles = makeStyles(theme => linksStyles(theme));

export default ({ links }) => {
  const classes = useStyles();
  return (
    <div className={classes.handbookContentContainer}>
      <Hidden smDown>
        <Typography variant="subtitle1" className={classes.heading} color="textSecondary">
          Articles
        </Typography>
      </Hidden>
      {links
        .filter(h => !h.isDisabled)
        .map(handbook => {
          return (
            <Typography variant="h6" color="textSecondary" key={handbook.id}>
              <NavLink to={handbook.id} className={classes.navLink} activeClassName="active">
                {handbook.name}
              </NavLink>
            </Typography>
          );
        })}
    </div>
  );
};
