import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(15)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    '& > h6': {
      fontSize: 20,
      fontWeight: 300,
      marginBottom: theme.spacing(7),
    },
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h6" color="primary">
        1. We collect personal information with the express purpose of trying to protect your privacy.
      </Typography>
      <Typography variant="h6" color="primary">
        2. Your personal information will ONLY be shared with third-parties specifically to opt you out of their
        services, and only when you personally execute these requests.
      </Typography>
      <Typography variant="h6" color="primary">
        3. We will never sell or rent our mailing list or user information, in any way shape or form. Never.
      </Typography>
      <Typography variant="h6" color="primary">
        4. Safe Shepherd uses cookies for analytics purposes only. We track user actions on our website to help make our
        service better, and not to target advertisements or benefit third-parties.
      </Typography>
      <Typography variant="h6" color="primary">
        5. You can wipe your account and all personal information from Safe Shepherd with one easily accessible link.
      </Typography>
      <Typography variant="h6" color="primary">
        6. We’re eager to hear your questions or concerns at privacy@safeshepherd.com
      </Typography>
    </Container>
  );
};
