import React from 'react';
import getUserFirebaseDoc from '../../../hooks/useFirestoreSubscribeDoc';
import { Helmet } from 'react-helmet';
import { Grid, Typography, CircularProgress, Button, Paper, Hidden } from '@material-ui/core';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';
import Landing from './Landing';

const commonStyles = theme => ({
  margin: theme.spacing(2, 5),
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
});

const sharedCardStyles = () => ({
  background: 'rgba(112, 112, 112, 0.10)',
});

const useStyles = makeStyles(theme => ({
  signUpButton: {
    textDecoration: 'none',
    '& > button': {
      padding: '15px 30px',
      fontSize: '1.1em',
      background: '#26C4C4',
    },
  },
  root: {
    padding: theme.spacing(5, 16),
  },
  signUpText: {
    overflow: 'hidden',
    color: theme.palette.text.primary,
    margin: 'auto',
  },
  header: {
    fontSize: '2.125rem',
    fontWeight: 400,
    margin: theme.spacing(0, 0.2, 1, 0.2),
    padding: theme.spacing(0, 0.2, 1, 0.2),
    color: theme.palette.text.primary,
  },
  linksCard: {
    margin: theme.spacing(5),
    padding: theme.spacing(2),
    background: theme.palette.text.primary,
    ...sharedCardStyles(),
  },
  paper: {
    ...commonStyles(theme),
  },
  signUpCard: {
    ...commonStyles(theme),
    ...sharedCardStyles(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70px',
  },
  links: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&.active': {
      color: theme.palette.secondary.main,
    },
  },
  landing: {
    padding: theme.spacing(6),
  },
  articletitle: {
    fontWeight: 400,
    fontSize: '2.125rem',
  },
}));

export default props => {
  const {
    location: { pathname },
  } = props;

  const classes = useStyles();
  const id = pathname.replace('/handbook/', '');
  const [result, isLoading] = getUserFirebaseDoc('handbooks', id);

  if (isLoading) {
    return <CircularProgress />;
  }

  const isHandbookHomepage = pathname === '/handbook/privacy-basics';
  const isRemovalGuide = result.type !== 'content';

  return (
    <>
      <Helmet>
        <title>
          {isHandbookHomepage
            ? `The Internet Privacy Handbook | Safe Shepherd`
            : result.type !== 'content'
            ? `How to Remove Your Personal Information from ${result.name} | Safe Shepherd`
            : `${result.name} | Safe Shepherd`}
        </title>
        <meta
          name="description"
          content={
            isHandbookHomepage
              ? `Learn how to find and remove your personal information from the internet using the # 1 Internet Privacy Handbook - Free!`
              : result.type !== 'content'
              ? `${result.name} collects and shares your personal information. Learn how to completely remove yourself from ${result.name} in our free Privacy Handbook`
              : `${result.name} | Safe Shepherd`
          }
        />
      </Helmet>
      {isHandbookHomepage ? (
        <div className={classes.landing}>
          <Landing />
        </div>
      ) : (
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs>
                <Typography variant="h2" className={classes.paper}>
                  <b>{result.name}</b>
                </Typography>
              </Grid>
              {isRemovalGuide && (
                <Grid item xs={12}>
                  <Paper className={classes.signUpCard} elevation={0}>
                    <Hidden xsDown>
                      <Typography variant="h6" className={classes.signUpText}>
                        Removing your personal information from {result.name} takes less than three minutes
                      </Typography>
                    </Hidden>
                    <a
                      href={process.env.REACT_APP_DASHBOARD_SIGNUP_SITE_URL}
                      rel="noopener noreferrer"
                      className={classes.signUpButton}
                    >
                      <Button variant="contained" color="secondary">
                        Create A Free Account
                      </Button>
                    </a>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {isRemovalGuide && (
              <>
                <Grid item xs={12} md={7}>
                  <div className={classes.paper}>
                    <Typography variant="h4" className={classes.header}>
                      <b>About {result.name}</b>
                    </Typography>
                    <Typography variant="h6">{result.aboutSite}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Paper className={classes.linksCard} elevation={0}>
                    <Typography variant="h6" color="primary">
                      <b>{result.name} Links</b>
                    </Typography>
                    <a href={result.optOutUrl} className={classes.links} alt="Opt Out">
                      &gt; Opt-Out Url
                    </a>
                    <br />
                    <a href={result.privacyPolicyUrl} className={classes.links} alt="Privacy Policy">
                      &gt; Privacy Policy
                    </a>
                  </Paper>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <div className={classes.paper}>
                <Typography variant="h2" className={classnames(classes.header, classes.articletitle)}>
                  <b>{result.articleTitle || ''}</b>
                </Typography>
                <ReactMarkdown source={result.content && result.content.replace(/\\/g, '')} />
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
