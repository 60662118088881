import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  headerContainer: {
    background: theme.palette.primary.main,
    opacity: 1,
    height: '25vh',
    minHeight: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      height: 397,
    },
    '& > *:first-child': {
      fontWeight: 'bold',
      opacity: 'unset',
    },
    '& > h1': {
      fontSize: '3rem',
      fontWeight: 400,
      color: 'white',
      textAlign: 'center',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    '& > h6': {
      color: 'white',
      opacity: 0.35,
    },
  },
}));

export default ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.headerContainer}>
      <Typography variant="h1">{title}</Typography>
      <Typography variant="h6" color="textSecondary">
        {subtitle}
      </Typography>
    </div>
  );
};
