import React from 'react';
import Header from '../components/Header';
import PrivacyHandbook from '../components/PrivacyHandbook';

export default () => {
  return (
    <>
      <Header title={'The Internet Privacy Handbook'} subtitle={'Presented by Safe Shepherd'} />
      <PrivacyHandbook />
    </>
  );
};
