import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container } from '@material-ui/core';
import FeaturedCircles from '../About/FeaturedCircles';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    marginTop: theme.spacing(12),
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    '& > h1': {
      marginBottom: theme.spacing(4),
      fontSize: '1.125rem',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        color: theme.palette.secondary.link,
        opacity: 0.3,
      },
    },
    '& > h3': {
      textAlign: 'center',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
      '& > span': {
        color: theme.palette.secondary.main,
      },
    },
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container maxWidth="md">
        <div className={classes.contentContainer}>
          <Typography variant="h1" color="primary">
            About Us
          </Typography>
          <Typography variant="h3" color="primary">
            Our mission is to <span>protect your privacy</span> and identity with around-the-clock Internet monitoring
            and security
          </Typography>
        </div>
        <FeaturedCircles />
      </Container>
    </div>
  );
};
