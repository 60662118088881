import React from 'react';
import SideBar from '../PrivacyHandbook/SideBar';
import TopBar from '../PrivacyHandbook/TopBar';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import handBookScreens from '../PrivacyHandbook/handBookScreens';
import { useFirestoreSubscribe } from '../../hooks';
import ScrollToTopRoute from '../ScrollToTopRoute';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

export default () => {
  const classes = useStyles();

  const [handbooks, isLoading] = useFirestoreSubscribe('handbooks');

  if (isLoading) {
    return <></>;
  }

  const filteredHandbooks = handbooks.reduce(
    (total, current) => {
      if (current.type === 'content') {
        total.contents = [...total.contents, current];
      } else {
        total.guides = [...total.guides, current];
      }
      return { ...total };
    },
    { guides: [], contents: [] }
  );

  return (
    <div className={classes.root}>
      <Router>
        <Hidden smDown>
          <SideBar {...filteredHandbooks} />
        </Hidden>
        <Hidden mdUp>
          <TopBar {...filteredHandbooks} />
        </Hidden>
        <Switch>
          {Object.keys(handBookScreens).map(path => (
            <ScrollToTopRoute path={path} exact component={handBookScreens[path]} key={path} />
          ))}
        </Switch>
      </Router>
    </div>
  );
};
