import { makeStyles } from '@material-ui/core/styles';
import email from '../../images/email.png';
import details from '../../images/details@2x.png';

const containerStyles = (theme, top, left) => ({
  paddingTop: theme.spacing(top),
  position: 'relative',
  marginLeft: left,
  '& > h2': {
    fontSize: '3rem',
    fontWeight: 600,
    marginBottom: theme.spacing(2.5),
  },
  '& > h6': {
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(4),
    fontWeight: 200,
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '10%',
    paddingTop: theme.spacing(2),
  },
});

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingBottom: theme.spacing(10),
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  instructionsContainer: {
    ...containerStyles(theme, 12, '12%'),
  },
  alertsContainer: {
    ...containerStyles(theme, 18, '16%'),
  },
  alertsImgWrapper: {
    marginLeft: '30%',
    width: 550,
    height: 475,
    backgroundSize: 'cover',
    background: `url(${email})`,
    marginTop: theme.spacing(4),
    [theme.breakpoints.between('sm', 'md')]: {
      width: 450,
      height: 375,
    },
  },
  instructionsImgWrapper: {
    width: 710,
    height: 525,
    backgroundSize: 'cover',
    background: `url(${details})`,
    marginTop: theme.spacing(4),
    [theme.breakpoints.between('sm', 'md')]: {
      width: 610,
      height: 425,
    },
  },
}));
