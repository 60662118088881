import React from 'react';
import CommonHowSection from '../Utils/CommonHowSection';

export default () => {
  const heading = () => {
    return <div>Straightfoward Instructions For How To Handle The Exposure</div>;
  };
  const secondaryText = `If a website doesn't allow us to automatically remove your information, we'll provide straightforward
  instructions for how to handle the exposure.`;

  return <CommonHowSection heading={heading()} secondaryText={secondaryText} isInstructionsSection={true} />;
};
