import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Button, Container } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import ElevationScroll from './ElevationScroll';
import { SSLogo } from '../../images';

const useStyles = makeStyles(theme => ({
  links: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  navLink: {
    textDecoration: 'none',
    color: theme.palette.link,
    margin: 'auto 30px',
    '&.active': {
      color: theme.palette.primary.main,
    },
  },
  logo: {
    height: theme.spacing(5),
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    padding: 0,
  },
  registrationLinks: {
    '& > a': {
      textDecoration: 'none',
    },
    '& > *:not(:first-child)': {
      marginLeft: 12,
    },
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <ElevationScroll>
      <AppBar position="sticky" color="inherit">
        <Container maxWidth="lg">
          <Toolbar className={classes.toolbar}>
            <div className={classes.links}>
              <NavLink className={classes.logoLink} to="/" title="SAFESHEPHERD">
                <SSLogo className={classes.logo} />
              </NavLink>
              <NavLink to="/" exact className={classes.navLink} activeClassName="active">
                Home
              </NavLink>
              <NavLink to="/how" className={classes.navLink} activeClassName="active">
                How it works
              </NavLink>
            </div>
            <div className={classes.registrationLinks}>
              <a href={process.env.REACT_APP_DASHBOARD_SIGNUP_SITE_URL} rel="noopener noreferrer">
                <Button color="primary" variant="contained">
                  Sign Up
                </Button>
              </a>
              <a href={process.env.REACT_APP_DASHBOARD_LOGIN_SITE_URL} rel="noopener noreferrer">
                <Button color="primary" variant="outlined">
                  Login
                </Button>
              </a>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
};
