import React from 'react';
import { Container, Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Forbes from '../../images/Forbes@2x.png';
import Time from '../../images/Time@2x.png';
import BusinessInsider from '../../images/business-insider@2x.png';
import Cnbc from '../../images/CNBC@2x.png';
import Mashable from '../../images/Mashable@2x.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(6),
    '& > h6': {
      marginBottom: theme.spacing(2.5),
      textAlign: 'center',
    },
    '& > hr': {
      border: `1px solid ${theme.palette.link}`,
      opacity: 0.4,
      marginTop: theme.spacing(5),
    },
  },
  gridWrap: {
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  logoContainer: {
    maxWidth: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex !important',
      alignItems: 'center',
      height: 100,
    },
    '& img': {
      maxHeight: 40,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
  },
  carousel: {
    '& .slick-prev:before, & .slick-next:before': {
      display: 'none',
    },
  },
}));

var carouselSettings = {
  dots: false,
  infinite: true,
  autoplay: true,
  initialSlide: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const logoImages = [
  { image: Forbes, name: 'Forbes' },
  { image: Time, name: 'Time', maxHeight: 28 },
  { image: BusinessInsider, name: 'BusinessInsider' },
  { image: Cnbc, name: 'CNBC' },
  { image: Mashable, name: 'Mashable', maxHeight: 100 },
];

export default () => {
  const classes = useStyles();

  const getLogoGrid = () => {
    return logoImages.map(logo => (
      <Grid className={classes.logoContainer} item xs={2} key={logo.name}>
        <img src={logo.image} alt={logo.name} style={{ maxHeight: !!logo && logo.maxHeight }} />
      </Grid>
    ));
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography variant="h6" color="textSecondary">
        Featured On
      </Typography>
      <Hidden smDown>
        <Grid container spacing={3} className={classes.gridWrap}>
          {getLogoGrid()}
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid className={classes.gridWrap}>
          <Slider {...carouselSettings} className={classes.carousel}>
            {getLogoGrid()}
          </Slider>
        </Grid>
      </Hidden>
      <hr />
    </Container>
  );
};
