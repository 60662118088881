import Home from './Home';
import How from './How';
import About from './About';
import PrivacyHandbook from './PrivacyHandbook';
import PrivacyPolicy from './PrivacyPolicy';
import ToS from './ToS';

export default {
  '/how': How,
  '/about': About,
  '/handbook/privacy-basics': PrivacyHandbook,
  '/handbook/:guideLink': PrivacyHandbook,
  '/privacy-policy': PrivacyPolicy,
  '/tos': ToS,
  '/': Home,
};
