import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
const breakpoints = createBreakpoints({});

// Overrides default Material-UI themes that can be found at: https://material-ui.com/customization/default-theme/
export default {
  palette: {
    primary: {
      main: '#071038',
    },
    secondary: {
      main: '#2CD9DA',
    },
    link: '#b5b5b5',
  },
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    h3: {
      lineHeight: '1.15em',
      [breakpoints.down('xs')]: {
        fontSize: '2.4rem',
      },
    },
    h6: {
      fontSize: '1.125rem',
    },
    button: {
      textTransform: 'initial',
      whiteSpace: 'nowrap',
    },
  },
  shape: { borderRadius: 12 },

  // If the rule is not injected by the theme, apply the extra styling using `overrides`
  overrides: {
    MuiButton: {
      root: {
        padding: '6px 24px',
        '& ~.MuiButton-root': {
          marginLeft: 12,
        },
      },
      outlined: {
        padding: '5px 23px',
      },
      containedSecondary: {
        color: 'white',
      },
    },
  },
};
