import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import TopNav from './components/TopNav';
import Footer from './components/Footer';
import { makeStyles } from '@material-ui/core/styles';
import screens from './screens';
import ScrollToTopRoute from './components/ScrollToTopRoute';
import { Helmet } from 'react-helmet';
import MixpanelPageView from './MixpanelPageView';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.grey[50],

    // Make sure site is at least 100vh so footer's always at the bottom.
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  mainContent: {
    flexGrow: 1,
  },
}));

function App() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>{process.env.REACT_APP_ENVIRONMENT !== 'production' && <meta name="robots" content="noindex" />}</Helmet>
      <Router>
        <TopNav />
        <div className={classes.mainContent}>
          <Switch>
            {Object.keys(screens).map(path => (
              <ScrollToTopRoute path={path} exact component={screens[path]} key={path}></ScrollToTopRoute>
            ))}
          </Switch>
        </div>
        <Footer />
        <MixpanelPageView />
      </Router>
    </div>
  );
}

export default App;
