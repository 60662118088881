import React from 'react';
import { Container } from '@material-ui/core';
import About from '../components/About';
import PageHead from '../components/Utils/PageHead';

export default () => (
  <Container maxWidth="lg">
    <PageHead
      title="About | Safe Shepherd"
      content="Safe Shepherd identifies and removes your personal information from over 100+ people-search websites. Over 200k satisfied users. Start protecting your information today."
    />
    <About />
  </Container>
);
