import React from 'react';
import { Typography, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cardImage from '../../images/RepeatGrid@2x.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  gridContainer: {
    width: '75%',
  },
  container: {
    paddingTop: theme.spacing(10),
    '& > h1': {
      fontSize: '3rem',
      fontWeight: 600,
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
    },
    '& > h6': {
      marginBottom: theme.spacing(4),
      fontWeight: 200,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
  imageContainer: {
    position: 'relative',
    marginTop: '-10%',
    marginLeft: '-2%',
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
    },
    '& > img': {
      position: 'absolute',
      right: 90,
      top: -45,
    },
  },
  imageWrapper: {
    background: `url(${cardImage})`,
    height: 730,
    width: 730,
    backgroundSize: 'cover',
    [theme.breakpoints.between('sm', 'md')]: {
      height: 650,
      width: 650,
    },
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} md={5} className={classes.container}>
          <Typography variant="body2" color="textSecondary">
            How It Works
          </Typography>
          <Typography variant="h1" color="primary">
            Safe Shepherd Protects Your Personal Information, 24/7
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Safe Shepherd constantly scans the internet and private databases, looking for your personal information.
            When we find a company publicizing or selling your personal information, we submit an opt-out request on
            your behalf, which deletes your record.
          </Typography>
        </Grid>
        <Grid item xs={7} className={classes.imageContainer}>
          <Hidden smDown>
            <div className={classes.imageWrapper} />
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
};
