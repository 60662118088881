import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    margin: '100px auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginRight: theme.spacing(27),
      marginLeft: theme.spacing(27),
    },
    '& > h4': {
      fontSize: 30,
      fontWeight: 'bold',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        fontSize: 25,
      },
    },
    '& > p': {
      marginBottom: theme.spacing(7.5),
      color: '#131B40',
      fontSize: '1.2em',
      lineHeight: '1.5em',
      opacity: 0.5,
    },
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} lg={5} className={classes.contentContainer}>
      <Typography variant="h4" color="primary">
        Want to use our product? Awesome. Read the rules.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        The following Terms of Service (“Terms,” “Agreement”) govern your use of Safe Shepherd LLC’s (“ Safe Shepherd “,
        “we” or “us”) website, products, and services (collectively referred to as our “Products”).
      </Typography>
      <Typography variant="h4" color="primary">
        If you don’t agree with the rules, don’t use our products.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        READ THIS AGREEMENT CAREFULLY. IF YOU USE OUR PRODUCTS, YOU (“user” or “you”) ARE ENTERING INTO THIS AGREEMENT
        WITH Safe Shepherd. THIS IS A LEGALLY BINDING AGREEMENT. If you don’t agree to these terms and conditions, you
        aren’t permitted to use Safe Shepherd Products.
      </Typography>
      <Typography variant="h4" color="primary">
        We may have to change the rules at some point, but we’ll tell you about it when we do.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        We may amend or terminate any terms of this Agreement at any time and such amendment or termination will be
        effective at the time we post the revised terms on the site. You can check to see when we last revised this
        agreement by looking at the “effective” date at the bottom of our Terms of Service page. If you continue to use
        our Products after we’ve posted revised terms, you have accepted the revised terms.
      </Typography>
      <Typography variant="h4" color="primary">
        Are you old enough to use our Products? You must be 18 or older.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        You represent that you’re 18 years old or older. You cannot use our Products if you are under 18.
      </Typography>
      <Typography variant="h4" color="primary">
        You can’t lie about who you are.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        You need to give us some of your personal information so that we can search for your records, and the
        information you give us has to be accurate. You represent that all of the information you submit to Safe
        Shepherd in connection with our Products is true and is about you, not someone else. You may not create
        duplicate accounts, or a create new account using someone else’s information.
        <br />
        <br />
        If you give us information that we suspect isn’t true, accurate, or about you, Safe Shepherd has the right to
        suspend or terminate your subscription and refuse any and all current or future use of our Products.
      </Typography>
      <Typography variant="h4" color="primary">
        You have to pay us in order to use some of our Products.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        If you’re using one of our paid products, you acknowledge that you’re registering for a recurring subscription
        and will be automatically billed at the end of the subscription billing cycle. You and Safe Shepherd have the
        right to cancel your subscriptions at any time, for any reason (this means that if you want us to stop charging
        you, you have to CANCEL your subscription). If your subscription is cancelled, you will not be charged again.
        Any termination of your subscription, whether initiated by you or Safe Shepherd, will not change any rights or
        obligations under these Terms.
      </Typography>
      <Typography variant="h4" color="primary">
        It’s really easy to cancel your subscription and/or delete your account.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        To cancel your subscription, log into your account, navigate to your Identity Vault, and click on the Membership
        button. Click the “Downgrade” button at the bottom of the page. To delete your account, log into your account,
        navigate to your Identity Vault, and click the “Delete Account” button at the bottom of the page.
      </Typography>
      <Typography variant="h4" color="primary">
        We do a lot more for you if you’re a VIP.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Before you become a VIP member, you’ll have to sign a separate agreement that allows us to provide personalized,
        comprehensive service. That said, you and Safe Shepherd remain bound by these terms even if you are a VIP. The
        only exceptions will be clearly noted in your VIP agreement and discussed with you before you sign the VIP
        agreement.
      </Typography>
      <Typography variant="h4" color="primary">
        If weird, unlikely things happen, you can’t sue us for them.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        You acknowledge that your use of Safe Shepherd Products may have unintended consequences, possibly including
        direct, special, indirect, consequential and other damages, and you agree that you won’t hold Safe Shepherd
        liable for these consequences. This means that weird things can happen that aren’t really our fault, and you
        can’t sue us for them.
        <br />
        <br />
        For instance, say you have a long-lost uncle who’s using Intelius to look for you, but because we deleted you
        from Intelius, it takes him much longer to find you. You agree that you won’t sue us because you lost the
        opportunity to spend more time with your uncle.
      </Typography>
      <Typography variant="h4" color="primary">
        Our Products only work if we have your permission to do the work.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        You’re letting us do the work of removing your personal info from people search websites and data broker
        databases. You grant Safe Shepherd a Limited Power of Attorney to act on your behalf for the purposes of
        removing your personal information from third party websites.
        <br />
        <br />
        This includes letting us submit opt-out requests and communicate with third-party websites like data brokers or
        aggregators or other parties who have control over this content, signing opt-out documents, creating accounts
        for you, and any other action Safe Shepherd believes is reasonably necessary to remove, suppress, or opt-out
        your personal information from unwanted sources in fulfillment of your request. This Limited Power of Attorney
        will remain in full force and effect until you cancel your subscription with Safe Shepherd,
      </Typography>
      <Typography variant="h4" color="primary">
        Much as we’d like to, we don’t control the sites that host your personal information.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        You acknowledge that our Products include blocking or interacting with third parties like people search sites.
        These third party sites often change their methods and processes, and in order to maximize the number of sites
        from which we can remove your information, we have to change our methods and processes accordingly.
        <br />
        <br />
        Our Products cannot remove all of your information from the Internet. Thus, our Products are provided on an “as
        is” basis, without warranties of any kind, either express or implied, including, without limitation, implied
        warranties of merchantability, fitness for a particular purpose, or non-infringement. You agree that, in any
        event, Safe Shepherd liability is limited to the fees you actually paid us.
      </Typography>
      <Typography variant="h4" color="primary">
        We’re darn good at what we do, but we aren’t perfect.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Safe Shepherd Products help you take control of your personal information online by helping you do things that
        include but are not limited to requesting that third party websites, such as data brokers and information
        aggregators, remove or suppress your personal information from their websites. You acknowledge that Safe
        Shepherd will make a good faith, reasonable effort to help you accomplish these privacy goals, but that we do
        not and cannot guarantee that third parties will, among other things, honor these requests, remove your data, or
        stop collecting your information. We cannot guarantee that our Products will eliminate or control the
        collection, use, or sharing of your online data.
      </Typography>
      <Typography variant="h4" color="primary">
        We are not your lawyers.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Although we employ lawyers, we cannot be your lawyer. We do not give legal advice, we do not represent you, and
        none of our communications with you are privileged. So please don’t rely on us for such things.
      </Typography>
      <Typography variant="h4" color="primary">
        And if someone sues you, it isn’t our fault.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        You are responsible for complying with all local, state, and federal laws when you use our Products. In any
        case, you will indemnify and hold harmless Safe Shepherd, its parents, subsidiaries, customers, vendors,
        officers, and employees from any liability, damage or cost from any claim or demand associated with your use of
        our Products.
      </Typography>
      <Typography variant="h4" color="primary">
        Also, we would appreciate it if you didn’t sue us.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        You agree that Safe Shepherd isn’t liable for any failure to comply with these Terms.
      </Typography>
      <Typography variant="h4" color="primary">
        But if you’re that upset, you’ll agree to binding arbitration.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Should a dispute arise, you agree to submit it for resolution by arbitration before the American Arbitration
        Association in San Francisco, CA, in accordance with the Commercial Arbitration Rules of the American
        Arbitration Association. And because we’re in California, California law will apply (without regard to conflict
        of law principles). Any award rendered shall be final and conclusive to the parties and judgment on the award
        rendered by the arbitrator(s) may be entered in any court having jurisdiction.
      </Typography>
      <Typography variant="h4" color="primary">
        Finally, our stuff belongs to us.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        All the text, images, marks, logos, compilations (meaning the collection, arrangement, and assembly of
        information) and other content on Safe Shepherd (“Site Content”), and all software embodied in Safe Shepherd
        applications, API, website, or otherwise (“Software”) used by to deliver the Products is proprietary to us.
        Except as otherwise expressly permitted by these Terms, any use, redistribution, sale, decompilation, reverse
        engineering, disassembly, translation, or other reduction of such software to human-readable form is prohibited.
      </Typography>
    </Grid>
  );
};
