import React from 'react';
import { Typography, Grid, Hidden } from '@material-ui/core';
import commonStyles from './CommonStyles';

export default ({ secondaryText, heading, isInstructionsSection = false }) => {
  const classes = commonStyles(isInstructionsSection);

  const getImageGrid = () => {
    return (
      <Grid item className={classes.imageContainer}>
        <div className={isInstructionsSection ? classes.instructionsImgWrapper : classes.alertsImgWrapper} />
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      {!isInstructionsSection && <Hidden mdDown>{getImageGrid()}</Hidden>}
      <Grid
        item
        xs={10}
        lg={4}
        className={isInstructionsSection ? classes.instructionsContainer : classes.alertsContainer}
      >
        <Typography variant="h2" color="primary">
          {heading}
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {secondaryText}
        </Typography>
      </Grid>
      {isInstructionsSection && <Hidden mdDown>{getImageGrid()}</Hidden>}
    </div>
  );
};
