import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid, Hidden } from '@material-ui/core';
import SeeHowButton from '../SeeHowButton';
import demoImage from '../../images/header@2x.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(7.5),
      flexDirection: 'column',
    },
  },
  container: {
    paddingTop: theme.spacing(19.5),
    marginLeft: '10%',
    '& > h1': {
      fontSize: '3rem',
      fontWeight: 600,
    },
    '& > h6': {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3.5),
      fontWeight: 200,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 'unset',
      marginRight: '10%',
      '& br': {
        display: 'none',
      },
    },
  },
  imageContainer: {
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: 230,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 430,
    },
    '& .imageWrapper': {
      width: '100%',
      height: '80%',
      borderRadius: '40px',
      margin: theme.spacing(10, 8, 0, 8),
      backgroundSize: 'cover',
      background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 45% , rgba(250, 250, 250, 1)),url(${demoImage})`,
      [theme.breakpoints.down('sm')]: {
        width: 'unset',
        borderRadius: '15px',
      },
    },
  },
  buttonContainer: {
    '& > h6': {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3.5),
      fontWeight: 200,
      width: '75%',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        width: 'unset',
      },
    },
  },
  link: {
    textDecoration: 'none',
    '& > button': {
      padding: `${theme.spacing(3.5)}px ${theme.spacing(10)}px`,
      width: '74%',
      marginBottom: theme.spacing(1.25),
      boxShadow: 'none',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& .firstButton': {
      color: 'white',
      background: '#26C4C4',
      fontSize: '1.2em',
      borderRadius: 19,
      opacity: 1,
    },
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid item xs={12} sm={12} lg={4} className={classes.container}>
        <Typography variant="h1" color="primary">
          Remove Your
          <br /> Personal Information
          <br /> From The Internet
        </Typography>
        <Typography variant="h6" color="textSecondary">
          Safe Shepherd proactively removes your personal information from the internet and marketing databases.
        </Typography>
        <Hidden mdUp>
          <Grid item xs={12} sm={12} className={classes.imageContainer}>
            <div className="imageWrapper" />
          </Grid>
        </Hidden>
        <div className={classes.buttonContainer}>
          <a href={process.env.REACT_APP_DASHBOARD_SIGNUP_SITE_URL} rel="noopener noreferrer" className={classes.link}>
            <Button variant="contained" className="firstButton">
              Create A Free Account
            </Button>
          </a>
          <Link to="/how" className={classes.link}>
            <SeeHowButton />
          </Link>
        </div>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={12} lg={8} className={classes.imageContainer}>
          <div className="imageWrapper" />
        </Grid>
      </Hidden>
    </div>
  );
};
