import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ContentsLinks from '../PrivacyHandbook/ContentsLinks';
import GuidesLinks from '../PrivacyHandbook/GuidesLinks';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '200px',
    padding: theme.spacing(4),
  },
  contents: {
    height: 300,
    overflow: 'scroll',
  },
  guides: {
    height: 300, // set back to 300 when articles are enabled
    overflow: 'scroll',
  },
}));

export default ({ guides, contents }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.contents}>
        <ContentsLinks links={contents} />
      </div>

      <div className={classes.guides}>
        <GuidesLinks links={guides} />
      </div>
    </div>
  );
};
