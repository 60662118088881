import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import SeeHowButton from '../SeeHowButton';
import { makeStyles } from '@material-ui/core/styles';
import { IntroCardOne, IntroCardTwo, IntroCardThree } from '../Intro/Cards';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    '& > *:first-child': {
      marginTop: theme.spacing(9),
    },
    '& > *:last-child': {
      marginTop: theme.spacing(-8),
    },
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(12.5),
    '& > h6': {
      marginBottom: theme.spacing(2.5),
    },
  },
  link: {
    textDecoration: 'none',
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid item xs={12} sm={12} lg={6}>
        <IntroCardOne />
        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.buttonContainer}>
          <Typography variant="h6" color="textSecondary">
            Have questions?
          </Typography>
          <Link to="/how" className={classes.link}>
            <SeeHowButton />
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <IntroCardTwo />
        <IntroCardThree />
      </Grid>
    </div>
  );
};
