import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Entrepreneur from '../../images/aboutUs/Entrepreneur_logo@2x.png';
import Time from '../../images/aboutUs/Time_logo@2x.png';
import Quartz from '../../images/aboutUs/Quartz_logo@2x.png';
import Forbes from '../../images/aboutUs/Forbes_logo@2x.png';
import Cnbc from '../../images/aboutUs/CNBC_logo@2x.png';
import BusinessInsider from '../../images/aboutUs/Business-insider_logo@2x.png';
import CNNMoney from '../../images/aboutUs/CNNMoney_logo@2x.png';
import Lifehacker from '../../images/aboutUs/Lifehacker_logo@2x.png';
import AmericanPublic from '../../images/aboutUs/American_Public_Media_logo.png';
import NewYorkPost from '../../images/aboutUs/New_York_Post_logo@2x.png';
import ReadWrite from '../../images/aboutUs/Read_Write_logo.svg@2x.png';
import Bloomberg from '../../images/aboutUs/Bloomberg_logo@2x.png';
import Mashable from '../../images/aboutUs/Mashable_logo@2x.png';
import PCmagazine from '../../images/aboutUs/PCmagazine_logo@2x.png';
import TheStar from '../../images/aboutUs/TheStar_logo@2x.png';
import Pandodaily from '../../images/aboutUs/Pandodaily_logo@2x.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7),
    },
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 260,
    height: 260,
    borderRadius: '50%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: 1,
    marginRight: theme.spacing(4),
    flexBasis: 'unset',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1.5),
      width: 142,
      height: 142,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 260,
      height: 260,
    },
    '& > img': {
      objectFit: 'contain',
      width: 100,
      height: 100,
    },
  },
  featuredText: {
    textAlign: 'left',
    marginTop: theme.spacing(7),
    position: 'relative',
    paddingLeft: theme.spacing(1.25),
    '& > hr': {
      position: 'absolute',
      width: 335,
      marginTop: theme.spacing(3),
      border: '1px solid #707070',
      opacity: 0.21,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: 'unset',
    },
  },
}));

const logoImages = [
  { image: Entrepreneur, name: 'Entrepreneur' },
  { image: Time, name: 'Time' },
  { image: Quartz, name: 'Quartz' },
  { image: Forbes, name: 'Forbes' },
  { image: Cnbc, name: 'CNBC' },
  { image: BusinessInsider, name: 'Business-Insider' },
  { image: CNNMoney, name: 'CNNMoney' },
  { image: Lifehacker, name: 'Lifehacker' },
  { image: AmericanPublic, name: 'American-Public' },
  { image: NewYorkPost, name: 'NewYorkPost' },
  { image: ReadWrite, name: 'ReadWrite' },
  { image: Bloomberg, name: 'Bloomberg' },
  { image: Mashable, name: 'Mashable' },
  { image: PCmagazine, name: 'PCmagazine' },
  { image: TheStar, name: 'TheStar' },
  { image: Pandodaily, name: 'Pandodaily' },
];

export default () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" color="textSecondary" className={classes.featuredText}>
        Featured On
        <hr />
      </Typography>
      <Grid container className={classes.root}>
        {logoImages.map(logo => {
          return (
            <Grid item xs={6} sm={6} md={4} lg={4} className={classes.circle} key={logo.name}>
              <img src={logo.image} alt={logo.name} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
