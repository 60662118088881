import React from 'react';
import { Container } from '@material-ui/core';
import HomeHeader from '../components/HomeHeader';
import Featured from '../components/Featured';
import Intro from '../components/Intro';
import { makeStyles } from '@material-ui/core/styles';
import PageHead from '../components/Utils/PageHead';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
    },
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <div>
      <PageHead
        title="Privacy & Identity Protection | Safe Shepherd"
        content="Safe Shepherd identifies and removes your personal information from over 100+ people-search websites. Over 200k satisfied users. Start protecting your information today."
      />
      <HomeHeader />
      <Container className={classes.root} maxWidth="lg">
        <Featured />
      </Container>
      <Intro />
    </div>
  );
};
