import React from 'react';
import { Container, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DesktopCard from './DesktopCard';
import CardSlider from '../Intro/CardSlider';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      overflow: 'hidden',
      padding: 'unset',
    },
    '& > h3': {
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(5),
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(5),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography variant="h3" color="primary">
        Block Creeps, Marketers
        <br />
        And Criminals
      </Typography>
      <Hidden smDown>
        <DesktopCard />
      </Hidden>
      <Hidden mdUp>
        <CardSlider />
      </Hidden>
    </Container>
  );
};
